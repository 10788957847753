//tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// @import "mask";
@import "fonts";
@import "typo";
@import "button";
@import "header";
@import "iconamenu";
@import "footer";
// @import "casebox";
// @import "imgbox";

@import "realizzazioni";
@import "evidenza";
@import "servizi";

// * COLORI

:root {
  --firstcolor: #f6efef;
}
// panna
// grigio
// nero

//* hack per gestire altezza 100% della prima section slider (esclusa la pagina con il video)
body:not(.work, .blog) {
  #sec1 {
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }
}

.container {
  @apply px-0 mx-auto;
}
.showMenu {
  @apply overflow-hidden;
}
.cta {
  @apply relative z-10 flex items-center gap-4 text-xl font-semibold md:mt-8;

  span {
    @apply transition-all underline-offset-8;
  }
  .arrow {
    @apply block w-5 h-5 mx-2 bg-frecciaNera sm:mx-4;
  }
  &:hover {
    span {
      @apply underline scale-110;
    }
  }
}

//SVG COLORS
.svgwhite path {
  fill: #ffffff;
}
.svgwhite {
  path {
    fill: #ffffff;
  }
}

//calc(((100% / 12) * 10) - (10px * 2))

.margin-bottom {
  margin-bottom: 15px;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

::-webkit-scrollbar {
  display: none;
}

form {
  input,
  textarea,
  checkbox {
    @apply px-2 py-1 text-base sm:p-2 #{!important};
  }
  input[type="submit"] {
    @apply py-2 px-4 text-base #{!important};
  }
  label {
    @apply block text-base;
  }
  ul {
    @apply flex;
  }
  li {
    @apply px-2 py-1 text-left;
  }
  .error {
    @apply bg-firstColor-500;
  }
  .success {
    @apply bg-green-200;
  }
}
