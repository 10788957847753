#imageGallery {
  figure {
    @apply relative;

    figcaption {
      @apply sm:absolute sm:text-center z-10 sm:left-1/2 sm:-translate-x-1/2 sm:w-[70%]  sm:-bottom-4 sm:h-12 p-2 leading-tight bg-white;
      font-size: 14px;

      @media only screen and (max-width: 480px) {
        font-size: 12px;
      }
    }

    img {
      @apply w-full h-full object-cover aspect-4/3 cursor-pointer;
    }
  }
}

#tagFilters {
  @apply flex-wrap justify-center mx-2 gap-y-2 mt-2;

  display: none !important;

  label {
    @apply w-1/2 lg:w-[17%] px-1 flex gap-1 leading-tight;

    @media only screen and (max-width: 480px) {
      font-size: 12px;
    }

    input {
      @apply w-4 h-4 mx-1 accent-black;
    }
  }
}

.lightbox-content {
  img {
    @apply max-h-screen;
  }
}
