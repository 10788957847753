/* ======= Services style  ======= */
.services-feat {
  color: #fff;
}
.services-feat .square-flip {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: relative;
  background-size: cover;
  background-position: center center;
}
.services-feat .square-flip {
  min-height: 420px;
  margin-bottom: 30px;
}
.services-feat .square,
.services-feat .square2 {
  width: 100%;
  height: 100%;
}
.services-feat .square {
  background-size: cover;
  background-position: center center;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.services-feat .square h4 {
  color: #fff;
  text-align: left;
  margin-bottom: 0;
  display: inline-flex;
}
.services-feat .square-flip .square {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.services-feat .square-flip:hover .square {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.services-feat .square2 {
  background: #f3ece5;
  background-size: cover;
  background-position: center center;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  overflow: hidden;
  position: absolute;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.services-feat .square2:before {
  top: 25px;
  left: 15px;
  right: 15px;
  bottom: 25px;
}

.services-feat .btn-line a {
  border-bottom: 1px solid rgba(197, 164, 126, 0.7);
  color: #555;
}
.services-feat .btn-line a:hover {
  border-bottom: 1px solid #c5a47e;
  color: #555;
}
.services-feat .square-flip .square2 {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 1;
}
.services-feat .square-flip:hover .square2 {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.services-feat .square-container {
  padding: 40px;
  text-align: center;
  height: 420px;
  position: relative;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -webkit-transform: translateX(0px) scale(1);
  -ms-transform: translateX(0px) scale(1);
  transform: translateX(0px) scale(1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 2;
}
.services-feat .square-container .box-title {
  /*	width: 100%;*/
}
.text-vertical {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
.services-feat .square-flip:hover .square-container {
  -webkit-transform: translateX(-650px) scale(0.88);
  -ms-transform: translateX(-650px) scale(0.88);
  transform: translateX(-650px) scale(0.88);
}
.services-feat .square-container2 {
  padding: 30px;
  text-align: left;
  position: relative;
  top: 50%;
  -webkit-transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -o-transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  transition: transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25), -webkit-transform 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.25);
  -webkit-transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
  transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  z-index: 2;
}
.services-feat .square-flip:hover .square-container2 {
  -webkit-transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
  transform: translateY(-50%) translateX(0px) translateZ(0px) scale(1);
}
.services-feat .flip-overlay {
  display: block;
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.3;
}
@media screen and (max-width: 991px) {
  .services-feat .square-flip {
    margin-bottom: 30px;
  }
  .services-feat {
    padding-bottom: 70px;
  }
}
