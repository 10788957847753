@font-face {
  font-display: swap;
  font-family: "Montserrat-Alt1";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/MontserratAlt1-Regular.woff2") format("woff2"), url("../fonts/MontserratAlt1-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Montserrat-Alt1";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/MontserratAlt1-SemiBold.woff2") format("woff2"), url("../fonts/MontserratAlt1-SemiBold.ttf") format("truetype");
}

// @font-face {
//   font-display: swap;
//   font-family: "Montserrat-Alt1";
//   font-style: normal;
//   font-weight: 700;
//   src: url("../fonts/MontserratAlt1-Regular.woff2") format("woff2"), url("../fonts/MontserratAlt1-Regular.ttf") format("truetype");
// }

// @font-face {
//   font-display: swap;
//   font-family: "Montserrat-Alt1";
//   font-style: normal;
//   font-weight: 800;
//   src: url("../fonts/MontserratAlt1-Regular.woff2") format("woff2"), url("../fonts/MontserratAlt1-Regular.ttf") format("truetype");
// }
