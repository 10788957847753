footer {
  @apply overflow-hidden;
  nav,
  p {
    @apply text-black;
  }

  a {
    @apply hover:underline hover:underline-offset-8;
  }

  nav {
    ul {
      @apply flex flex-wrap gap-4 sm:gap-6 pb-8;

      li {
        @apply w-full sm:w-auto;
      }
    }
    a {
      @apply relative  z-10 text-[21px] font-light;

      &.active {
        @apply underline underline-offset-8;
      }

      span {
        @apply text-black;
      }
    }
  }

  .contact {
    @apply flex flex-col gap-8;

    a:not(.whatsapp) {
      @apply relative z-10 block text-black;
    }
  }

  .info {
    span,
    a {
      @apply mb-2 block after:mx-2 after:hidden after:content-['-'] last:after:hidden sm:mb-0 sm:inline-block sm:after:inline-block;
    }
  }
}
