//MENU

.navbar-menu {
  @apply fixed z-0 inset-0 w-full h-screen bg-firstColor-500 opacity-0;

  // forzo apertura menu

  // @apply fixed z-0 inset-0 w-full h-screen bg-firstColor-500 translate-x-0 #{!important};

  .image-container {
    .image {
      @apply absolute inset-0 z-10 object-cover w-full h-full maxheight:h-96 aspect-square;
    }
  }

  nav {
    @apply max-w-7xl relative z-40 mx-auto mt-[20vw] gap-2 flex  md:gap-4 w-full flex-col items-start lg:items-center overflow-y-auto px-8 sm:px-0 sm:justify-evenly lg:mt-auto md:h-screen sm:flex-row maxheight:h-auto maxheight:items-start maxheight:justify-start sm:maxheight:mt-32;

    ul {
      @apply w-full md:w-1/2 left-0 z-10  max-w-2xl sm:pl-8 lg:pl-32 mb-4 sm:mb-0 md:gap-6 lg:gap-12 maxheight:gap-0;

      li {
        @apply relative mt-4 sm:mt-8;

        a {
          @apply text-black relative z-10 inline-block text-xl transition-all sm:text-2xl lg:text-3xl xl:text-3xl sm:maxheight:text-2xl px-2 whitespace-nowrap  font-secondFont uppercase;

          &:after {
            @apply absolute -z-10 h-auto w-full transition-all delay-100 ease-in content-[''] top-auto -bottom-2 left-0 hover:bottom-0 hover:h-8 md:hover:h-4 hover:bg-opacity-40 hover:bg-zinc-400;
          }
        }
      }
    }
  }

  // nav {
  //   @apply container relative z-40 mx-auto mt-36 flex h-[calc(100%-9rem)] w-full flex-col items-center overflow-y-auto px-8 sm:px-0 md:justify-evenly lg:mt-auto lg:h-screen lg:flex-row maxheight:h-full maxheight:items-start maxheight:justify-start sm:maxheight:mt-40 sm:maxheight:pb-40;

  //   ul {
  //     @apply absolute bg-firstColor-500 left-0 z-10 w-[50vw] grid max-w-2xl grid-cols-1 px-8 sm:grid-cols-2 lg:gap-12 maxheight:gap-0 py-4;
  //     li {
  //       @apply relative;
  //       a {
  //         @apply text-black relative z-10 block h-6 text-xl  transition-all sm:h-16 sm:text-3xl md:hover:text-4xl xl:text-3xl xl:hover:text-4xl sm:maxheight:text-3xl;

  //         span {
  //           @apply absolute block whitespace-nowrap hover:after:bottom-0 hover:after:top-8/12 hover:after:w-10/12 hover:after:bg-firstColor-800 sm:hover:after:top-auto sm:hover:after:h-[1px];
  //           &:after {
  //             @apply absolute top-7/12 left-2 -z-10 h-[2px] w-full transition-all content-[''] sm:top-auto sm:bottom-0;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .contact {
  //     @apply flex flex-col content-end w-full gap-4 px-8 mt-4 text-sm text-left text-black sm:px-16 sm:text-right sm:text-lg md:flex md:flex-row md:items-end md:px-8 md:text-left lg:mt-0 lg:w-auto lg:flex-col;

  //     & > div {
  //       @apply text-left text-black md:w-1/2 lg:w-full;

  //       a {
  //         @apply block text-black hover:text-firstColor-500;
  //       }
  //       p {
  //         @apply mb-0 text-black;
  //       }
  //     }
  //   }
}
