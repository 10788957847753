.wrapper-progetto {
  .titolo {
    @apply pb-2 mb-6 text-lg font-semibold;

    line-height: 1.2em;

    span {
      @apply hidden;
    }
  }
}

.wrapper-thumbs {
  img {
    @apply w-1/6 aspect-4/3 object-cover cursor-pointer;
  }
}
