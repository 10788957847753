body {
  @apply font-secondFont text-black;
}
p {
  @apply mb-6 text-black only:mb-0;
}

.check {
  @apply w-full;
  li {
    @apply my-0 pl-8 font-normal before:absolute before:h-4 before:w-4 before:translate-y-2 before:-translate-x-8 before:bg-check before:bg-cover before:bg-no-repeat;
  }
}

.lineheight1em {
  line-height: 1em !important;
}
.lineheight11em {
  line-height: 1.1em !important;
}
.lineheight12em {
  line-height: 1.2em !important;
}
.lineheight13em {
  line-height: 1.3em !important;
}
.lineheight14em {
  line-height: 1.4em !important;
}
.lineheight15em {
  line-height: 1.5em !important;
}

ul.small {
  li {
    @apply text-xs list-outside list-disc ml-4;
  }
}

a {
  &.lead {
    @apply text-firstColor-500 underline decoration-white decoration-dotted decoration-2 underline-offset-4 hover:decoration-firstColor-300;
  }
}

.ul-first {
  @apply underline decoration-firstColor-500 decoration-4 underline-offset-4;
}
.bg-first {
  @apply bg-firstColor-500 p-2;
}
.gsap-highlight {
  @apply relative inline-block;
}
.textup {
  &.double-line-height {
    .SplitTextJS-wrapper {
      @apply mb-2;
    }
  }
}
